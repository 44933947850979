import {useContext, useEffect, useState} from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { getDiscountPrice } from '@utils/helpers'
import { productImpressions } from '@utils/analytics'

/* components */
import BundleItem from "@components/plp/BundleItem"
import BundleGroupItem from "@components/plp/BundleGroupItem"
import Skeleton from "@components/plp/Skeleton"

/* styles */
import style from "@styles/plp/BundleList.module.scss"

/* context */
import context from "@context/plp"

export default function BundleList({ preview, bundles, activeFilters, sortOption }) {
  const { state } = useContext(context)
  const { t } = useTranslation()
  const [visibleCount, setVisibleCount] = useState(6)
  const [loadingMore, setLoadingMore] = useState(false)
  const [impressions, setImpressions] = useState([])

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  const applyFilters = (bundle) => {
    const { active: activeFilters } = state.filters
  
    // Check gender filter
    if (activeFilters.gender) {
      const genderToCheck = [...activeFilters.gender]
      if (genderToCheck.includes('men') || genderToCheck.includes('women')) {
        genderToCheck.push('unisex')
      }
      if (!genderToCheck.includes(bundle.gender)) {
        return false
      }
    }
  
    // Check activity filter
    if (activeFilters.activity) {
      const bundleActivities = bundle.activity ? bundle.activity.split(';').map(activity => activity.toLowerCase()) : []
      if (!activeFilters.activity.every(value => bundleActivities.includes(value))) {
        return false
      }
    }
  
    // Check accessories filter
    if (activeFilters.accessories) {
      const isAccessories = bundle.accessories_bundle === 1 ? 'accessories' : 'not accessories'
      if (!activeFilters.accessories.includes(isAccessories)) {
        return false
      }
    }
  
    return true
  }

  const createRenderList = () => {
    const renderList = bundles.map(bundleOrGroup => {
      if (bundleOrGroup.gender) {
        const isFiltered = applyFilters(bundleOrGroup)
        return isFiltered ? bundleOrGroup : null
      } else {
        const filteredBundles = bundleOrGroup.bundles.filter(applyFilters)
        return filteredBundles.length > 0 ? { ...bundleOrGroup, bundles: filteredBundles } : null
      }
    }).filter(Boolean)
  
    return renderList
  }

  let renderList = createRenderList()
  let sortedList = [...renderList]

  const getLocalizedBundleName = (bundle) => {
    if (bundle.translations && bundle.translations[currentLanguage]) {
      return bundle.translations[currentLanguage]
    }
    return bundle.name
  }

  if (sortOption === 1 || sortOption === 2) {
    sortedList = sortedList.flatMap(bundleOrGroup => {
      if (bundleOrGroup.bundles) {
        return bundleOrGroup.bundles.map(bundle => ({
          ...bundle,
          name: getLocalizedBundleName(bundle)
        }));
      } else {
        return bundleOrGroup
      }
    })

    if (sortOption === 1) {
      sortedList.sort((a, b) => getDiscountPrice(a.price, a.discount) - getDiscountPrice(b.price, b.discount))
    } else if (sortOption === 2) {
      sortedList.sort((a, b) => getDiscountPrice(b.price, b.discount) - getDiscountPrice(a.price, a.discount))
    }
  }

  const hasThreeItemsBundles = sortedList.some(b => b.products && b.products.length > 2)
  const visibleItems = sortedList.slice(0, visibleCount)

  useEffect(() => {
    const toSend = []
    const tmpImpressions = JSON.parse(JSON.stringify(impressions))
    let initialIndex = -1
    const combinedBundles = []
    for(const index in visibleItems) {
      if (tmpImpressions.indexOf(visibleItems[index].id) < 0) {
        toSend.push(visibleItems[index])
        tmpImpressions.push(visibleItems[index].id)
      } else {
        const item = visibleItems[index]
        if (item.bundles) {
          combinedBundles.push(...item.bundles)
        } else {
          combinedBundles.push(item)
        }
      }
    }
    if (toSend.length) {
      for(const index in combinedBundles) {
        const item = combinedBundles[index]
        try {
          const products = JSON.parse(item.products || '[]');
          initialIndex+=products.length
        } catch (e) {
        }
      }
      productImpressions(toSend, initialIndex)
      setImpressions(tmpImpressions)
    }
  }, [visibleItems, impressions])

  const handleShowMore = () => {
    setLoadingMore(true)
    
    setTimeout(() => {
      setVisibleCount(visibleCount + 6)
      setLoadingMore(false)
      
      setTimeout(() => {
        window.scrollBy({
          top: 1,
          behavior: 'smooth'
        });
  
        setTimeout(() => {
          window.scrollBy({
            top: -1,
            behavior: 'smooth'
          });
        }, 50);
        
      }, 100);
    }, 1500)
  }

  return (
    <div className={`${style.container} ${activeFilters ? style.activeFilters : ''}`}>
      {state.loading ? (
        <Skeleton visible={true} />
      ) : (
        <>
          {visibleItems.length > 0 ? (
            visibleItems.map((item, index) => {
              if (item.gender) {
                return (
                  <BundleItem key={index} bundle={item} preview={preview} threeItemsLayout={hasThreeItemsBundles} />
                )
              } else {
                return (
                  <BundleGroupItem key={index} bundleGroup={item} preview={preview} context="listing" />
                )
              }
            })
          ) : (
            <Skeleton visible={true} />
          )}

          {visibleCount < (sortOption === 0 ? renderList : sortedList).length && (
            <div className={style.showMoreContainer}>
              {loadingMore ? (
                <Skeleton visible={loadingMore} />
              ) : (
                <Link onClick={handleShowMore} className={style.showMore}>{t("plp.SHOW-MORE")}</Link>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}