import { useEffect, useState } from 'react'
import style from '@styles/plp/Skeleton.module.scss'

export default function Skeleton({ visible }) {
    const [show, setShow] = useState(visible)

    useEffect(() => {
        if (visible) {
            setShow(true)
        } else {
            const timeout = setTimeout(() => setShow(false), 500)
            return () => clearTimeout(timeout)
        }
    }, [visible]);

    return (
        show && (
            <div className={`${style.loaderContainer} ${visible ? style.fadeIn : style.fadeOut}`}>
                <div className={style.loaderHeader}></div>
                <div className={style.loaderBody}>
                    <div className={style.loaderBox}></div>
                    <div className={style.loaderBox}></div>
                    <div className={style.loaderBox}></div>
                </div>
                <div className={style.loaderFooter}></div>
                <div className={style.loaderButton}></div>
            </div>
        )
    )
}