import { useEffect, useReducer, useState, useRef } from 'react'
import { getPublicBundleList, getPublicBundleGroupList } from '@utils/fetch'
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next"

/* components */
import Header from '@components/plp/Header'
import Filters from '@components/plp/Filters'
import BundleList from '@components/plp/BundleList'
import ListingNotFound from '@components/plp/ListingNotFound'
import Skeleton from '@components/plp/Skeleton'

/* context */
import context, { initialContext } from '@context/plp'
import reducer from '@context/plp/reducer'
import { setBundles, setBundleGroup, setQueryFilters, setCombinedResults, setBundlesFilters, clearAllFilters } from '@context/plp/actions'
import { onExperienceStart } from '@utils/analytics'
import { getActiveFiltersFromURL, filterBundleList, getFiltersFromBundles } from '@utils/listing'
import debounce from "lodash.debounce";

function Listing() {
  const [state, dispatch] = useReducer(reducer, initialContext)
  const [contentVisible, setContentVisible] = useState(false)
  const [preview, setPreview] = useState(false)
  const [urlChanged, setUrlChanged] = useState(0)
  const location = useLocation()
  const { t } = useTranslation()
  const initialBundlesRef = useRef([])
  const initialBundleGroupRef = useRef([])

  const debouncedUrlChanged = useRef(
      debounce(() => {
        const rootElement = document.getElementById('root')
        if (rootElement) {
          clearAllFilters({ dispatch })
          setTimeout(() => {
            setUrlChanged((new Date()).getTime())
          }, 200);
        }
      }, 500),
  ).current

  useEffect(() => {
    const handleEvent = () => {
      debouncedUrlChanged()
    }

    window.addEventListener('state-changed', handleEvent);
    return () => window.removeEventListener('state-changed', handleEvent)
  }, [])

  useEffect(() => {
    debouncedUrlChanged.cancel()
    const previewParam = window.location.href.indexOf("?preview=") > 0
    setPreview(previewParam)

    const fetchData = async () => {
      try {
        const publishedParam = previewParam ? 0 : 1
        const [bundleGroupData, bundleData] = await Promise.all([
          getPublicBundleGroupList({ published: publishedParam, orderBy: 'cross_order' }),
          getPublicBundleList({ published: publishedParam, orderBy: 'cross_order' })
        ])

        if (bundleData.status === 200 && bundleGroupData.status === 200) {
          initialBundlesRef.current = bundleData.bundles
          initialBundleGroupRef.current = bundleGroupData.bundleGroup

          setBundles({ dispatch, t }, bundleData.bundles)
          setBundleGroup({ dispatch, t }, bundleGroupData.bundleGroup)

          const combinedResults = [...bundleData.bundles, ...bundleGroupData.bundleGroup]
          setCombinedResults({ dispatch }, combinedResults)

          const filters = getFiltersFromBundles(combinedResults, t)
          setBundlesFilters({ dispatch, state }, filters, t)

          const filtersFromURL = getActiveFiltersFromURL(window.location.hash.replace('#/', ''), filters)
          setQueryFilters({ dispatch }, filtersFromURL)

          setTimeout(() => setContentVisible(true), 800)

          const originalScrollRestoration = window.history.scrollRestoration
          window.history.scrollRestoration = 'manual'

          setTimeout(() => {
            const scrollY = window.scrollY; 
            requestAnimationFrame(() => {
              window.scrollTo(0, scrollY + 1)
              requestAnimationFrame(() => {
                window.scrollTo(0, scrollY)
              })
            })

            window.history.scrollRestoration = originalScrollRestoration
          }, 900)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
    onExperienceStart()

    const banner = document.querySelector(
      "#body-container > div > div:nth-child(1) > div > div > picture"
    )
    if (banner) {
      banner.style.display = "block"
    }
  }, [location.search, dispatch, t, urlChanged])
  
  const excludedBundleIds = new Set(
    state.bundleGroup.flatMap(group => {
      const groupBundles = Array.isArray(group.bundles) ? group.bundles : JSON.parse(group.bundles)
      return groupBundles.map(bundle => bundle.id)
    })
  )

  // Filter out bundles with discount 0 or null
  const filteredBundles = state.bundles.filter(bundle => {
    return !excludedBundleIds.has(bundle.cross_bundle_id) && 
      bundle.discount > 0 && bundle.discount !== null
  })

  const combinedBundles = [...filteredBundles, ...state.bundleGroup].sort((a, b) => a.cross_order - b.cross_order)

  const filteredCombinedBundles = combinedBundles.map(bundleOrGroup => {
    const activeFiltersWithUnisex = {
      ...state.filters.active,
      gender: state.filters.active.gender?.includes('men') || state.filters.active.gender?.includes('women') 
          ? [...state.filters.active.gender, 'unisex']
          : state.filters.active.gender,
    };

    const filteredBundlesByDates = bundleOrGroup.bundles.filter(bundle =>
      (new Date(bundle.start_at) <= new Date() && new Date(bundle.end_at) >= new Date()) ? true : false
    )
    bundleOrGroup = { ...bundleOrGroup, bundles: filteredBundlesByDates }


    if (bundleOrGroup.gender) {
        return filterBundleList(bundleOrGroup, state.filters.active) ? bundleOrGroup : null;
    } else {
        const filteredBundles = bundleOrGroup.bundles.filter(bundle => 
            !state.filters.active.gender || state.filters.active.gender.length === 0
            ? true 
            : filterBundleList(bundle, activeFiltersWithUnisex)
        );
        return filteredBundles.length > 0 ? { ...bundleOrGroup, bundles: filteredBundles } : null;
    }
  }).filter(Boolean)

  useEffect(() => {
    const updatedFilters = getFiltersFromBundles(filteredCombinedBundles, t)
    if (JSON.stringify(state.filters.all) !== JSON.stringify(updatedFilters)) {
      setBundlesFilters({ dispatch, state }, updatedFilters, t)
    }

    if (contentVisible) {
      window.scrollBy({
        top: 1,
        behavior: 'smooth'
      });
      window.scrollBy({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [filteredCombinedBundles, dispatch, t, state.filters.all])

  const activeFilters = Object.keys(state.filters.active).length > 0

  return (
    <context.Provider value={{ state, dispatch }}>
      <div className="b-app">
        {state.loading || state.filterLoading || filteredCombinedBundles.length === 0 ? (
          <Skeleton visible={true} />
        ) : (
          <div className={`b-container ${contentVisible ? "" : "b-container--hidden"}`}>
            <Header titleKey="plp.BUNDLES.TITLE" subtitleKey="plp.BUNDLES.DESC" />
            {filteredCombinedBundles.length === 0 ? (
              <ListingNotFound />
            ) : (
              <>
                <Filters />
                {state.filterLoading ? (
                  <Skeleton visible={true} />
                ) : (
                  <BundleList preview={preview} bundles={filteredCombinedBundles} activeFilters={activeFilters} sortOption={state.filters.sortOption} />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </context.Provider>
  )
}

export default Listing