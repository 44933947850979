import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';

/* styles */
import style from '@styles/plp/ListingNotFound.module.scss';

export default function ListingNotFound() {
    const { t } = useTranslation('translation');
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowContent(true);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!showContent) {
        return null;
    }

    return (
        <div className={style.textContainer}>
            <h2 className={style.title}>{t('plp.NO-ACTIVE-BUNDLE-TITLE')}</h2>
            <p className={style.text}>{t('plp.NO-ACTIVE-BUNDLE-DESC')}</p>
        </div>
    );
}