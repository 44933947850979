import { useTranslation } from "react-i18next";
import "../../i18n";

export default function TNFLoader() {
  const { t } = useTranslation("translation");
  return (
    <div
      id="loading-spinner-plp-comapre"
      className="loading-spinner"
      role="alert"
      aria-live="assertive"
    >
      <div className="activity-indicator"></div>
      <div className="loading-spinner-text">{t("loading")}</div>
    </div>
  );
}
