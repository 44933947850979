import { useEffect, useState, useContext } from "react"

import { getGalleryModal } from "@utils/detail";

/* styles */
import style from "@styles/pdp/SliderGallery.module.scss";

import context from "@context/pdp";

import { toggleSliderGallery } from "@context/pdp/actions";
import { ReactComponent as Close } from "@svg/close.svg";

import CircleLoader from "@components/plp/CircleLoader";

import "../../styles/galleryOverwrite.scss";


export default function SliderGallery() {
  const [html, setHtml] = useState();
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useContext(context);

  const handleKeyEsc = (event) => {
    if (event.key === "Escape") {
      toggleSliderGallery({ dispatch });
    }
  };
  useEffect(() => {
    getGalleryModal(
      state.step.product.uniqueID,
      state.step.product.variant.code
    ).then((response) => {
      setHtml(response);

      setTimeout(() => {
        const script = document.querySelector("#product-imgs > div > script")
        // eslint-disable-next-line no-new-func
        const myScript = new Function(script.text);
        myScript();
      }, 250);

      setTimeout(() => {
        setLoading(false)
      }, 1200);

    });

    document.addEventListener("keydown", handleKeyEsc, false);

    return () => {
      document.removeEventListener("keydown", handleKeyEsc, false);
    };
  }, []);

  const getContentClassName = () => {
    if (loading) return style.content + ' ' + style.contentHidden
    return style.content;
  }

  return <div className={style.container}>
    <div className={style.wrapper}>
      {loading && <div className={style.loader}><CircleLoader /></div>}
      <button className={style.closeCta} onClick={() => toggleSliderGallery({ dispatch })}><Close /></button>
      <div className={getContentClassName()}dangerouslySetInnerHTML={{ __html: html }}></div>
    </div>
  </div>
}