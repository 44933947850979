import { createContext } from "react";
import { getStoreKey } from "@utils/detail";

const context = createContext();
export default context

const pageType = document.getElementById('root').getAttribute('data-page-type') || 'bundle-group'
const isBundlePage =  pageType === 'bundle-group'

const initialContext = {
    bundleConfiguration: {},
    sliderGallery: false,
    stepBarMinimized: true,
    mouseoverSize: {},
    isBundlePage
}

export function getInitialContext(bundleId) {
    const storeKey = getStoreKey(bundleId)
    const storedState = JSON.parse(sessionStorage.getItem(storeKey))

    return storedState || initialContext
}
