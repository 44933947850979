import { useEffect, useReducer } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import "../i18n";

/* components */
import Header from '@components/pdp/Header';
import Gallery from '@components/pdp/Gallery';
import Info from '@components/pdp/Info';
import TNFLoader from "@components/generic/TNFLoader";
import StepBar from '@components/pdp/StepBar';
import MobileProductBar from '@components/pdp/MobileProductBar';
import ScrollToTop from "@components/generic/ScrollToTop";
import SizesChartModal from '@components/pdp/SizesChartModal';
import SliderGallery from '@components/pdp/SliderGallery';

/* styles */
import style from '@styles/pages/Detail.module.scss';

/* context */
import context, { getInitialContext } from '@context/pdp';
import reducer from '@context/pdp/reducer';
import { setStep, getBundle } from '@context/pdp/actions';

export default function Detail({ preview }) {
  const { id } = useParams()
  const location = useLocation()
  const { t } = useTranslation("translation")
  const [state, dispatch] = useReducer(reducer, getInitialContext(id))

  const searchParams = new URLSearchParams(location.search)
  const initialStep = parseInt(searchParams.get('step')) || 0

  useEffect(() => {
    getBundle({ state, dispatch }, id, preview ? 0 : 1)
  }, [id, preview])

  useEffect(() => {
    if (state.bundle && state.bundle.products && state.bundle.products.length > 0) {
      setStep({ state, dispatch }, initialStep)
    }
  }, [id, state.bundle, initialStep])

  useEffect(() => {
    if (window.globalThis) {
      window.globalThis.miniCartTranslation = {
        "item-added": t("mini-cart.item-added"),
        "proceed-to-checkout": t("mini-cart.proceed-to-checkout"),
        "view-basket": t("mini-cart.view-basket"),
        "continue-shopping": t("mini-cart.continue-shopping"),
      };
    }
    const banner = document.querySelector(
      "#body-container > div > div:nth-child(1) > div > div > picture"
    );
    if (banner) {
      banner.style.display = "none";
    }
  }, [t])

  if (!state.bundle || !state.step?.product) {
    return (
      <div className="b-app b-app-detail">
        <ScrollToTop />
        <TNFLoader />
      </div>
    )
  }

  return (
    <context.Provider value={{ state, dispatch }}>
      {state.sliderGallery && <div className="b-app">
        <SliderGallery />
      </div>}
      <ScrollToTop />
      <div className="b-app b-app-detail">
        {state.cartLoading && <div className={style.loadingOverlay}>
          <TNFLoader />
        </div>}
        {state.isBundlepage && (
            <MobileProductBar />
        )}
        <div className="b-container-fill">
          <Header preview={preview} state={state} />
        </div>
        {!state.loading ? (
          <div className="b-container">
            <div className={style.wrapper}>
              {state.sizesPopup && <SizesChartModal />}
              <Gallery />
              <Info />
            </div>
          </div>
        ) : (
          <TNFLoader />
        )}
        <StepBar />
      </div>
    </context.Provider>
  )
}