import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import "../../i18n"

/* styles */
import style from "@styles/plp/BundleItem.module.scss"

/* utils */
import { prependLocaleInUrl } from "@utils/helpers"

/* utils */
import BundleItem from "./BundleItem"

export default function BundleGroupItem({ bundleGroup, bundle, preview, context, prices }) {
  const { t, i18n } = useTranslation("translation")
  let bundles = []

  if (context === 'collection') {
    bundles = Array.isArray(bundleGroup.bundles) ? bundleGroup.bundles : JSON.parse(bundleGroup.bundles || '[]')
  } else if (context === 'listing') {
    bundles = bundle ? [bundle] : (Array.isArray(bundleGroup.bundles) ? bundleGroup.bundles : JSON.parse(bundleGroup.bundles || '[]'))
  }

  const imageRef = useRef(null)
  const infoRef = useRef(null)
  const containerRef = useRef(null)
  const titleRef = useRef(null)
  const [position, setPosition] = useState()
  const [top, setTop] = useState()
  const [titlePosition, setTitlePosition] = useState('static')
  const [titleWidth, setTitleWidth] = useState()
  const [titleTop, setTitleTop] = useState('0')
  const [lastScrollY, setLastScrollY] = useState(0)
  const [isImageVisible, setIsImageVisible] = useState(true)

  const currentLanguage = i18n.language

  useEffect(() => {
    const handleScroll = async () => {
      if (!imageRef.current || !infoRef.current || !containerRef.current || !titleRef.current) return

      const imageRect = imageRef.current.getBoundingClientRect()
      const infoRect = infoRef.current.getBoundingClientRect()
      const containerRect = containerRef.current.getBoundingClientRect()
      const titleRect = titleRef.current.getBoundingClientRect()
      const currentScrollY = window.scrollY
      const menuHeight = 113

      const isScrollingDown = currentScrollY > lastScrollY
      const isScrollingUp = currentScrollY < lastScrollY

      const isLandscape = imageRef.current.parentElement.classList.contains(style.imgLandscape)

      if (isLandscape) {
        if (containerRect.top >= 550 + menuHeight) {
          setPosition('absolute')
          setTop('-550px')
          imageRef.current.style.width = '100%'
          setIsImageVisible(true)
        } else if (isScrollingDown && imageRect.top >= infoRect.top + 80) {
          setIsImageVisible(false)
        } else if (isScrollingDown && imageRect.top <= menuHeight && imageRect.bottom < infoRect.bottom) {
          setPosition('fixed')
          setTop(`${menuHeight}px`)
          imageRef.current.style.width = `${containerRect.width}px`
          setIsImageVisible(true)
        } else if (isScrollingUp && imageRect.top === 0 && imageRect.bottom > infoRect.bottom) {
          setPosition('fixed')
          setTop(`${menuHeight}px`)
          imageRef.current.style.width = `${containerRect.width}px`
        } else if (isScrollingUp && imageRect.top <= infoRect.top + 80) {
          setIsImageVisible(true)
        }
        setTitlePosition('sticky')
        setTitleTop(`${menuHeight}px`)
      } else {
        if (containerRect.top >= 150 + menuHeight) {
          setPosition('absolute')
          setTop('-150px')
          imageRef.current.style.width = 'inherit'
        } else if (isScrollingDown && imageRect.top <= menuHeight && imageRect.bottom < infoRect.bottom) {
          setPosition('fixed')
          setTop(`${menuHeight}px`)
        } else if (isScrollingUp && imageRect.top > menuHeight && imageRect.bottom > infoRect.bottom) {
          setPosition('fixed')
          setTop(`${menuHeight}px`)
        } else if (imageRect.bottom >= infoRect.bottom + 150) {
          setPosition('absolute')
          setTop(`${infoRect.height - imageRect.height + 150}px`)
        }
      }

      setLastScrollY(currentScrollY)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScrollY])

  const imageStyleMapping = {
    left: style.imgLeft,
    right: style.imgRight,
    landscape: style.imgLandscape
  }
  const imageClass = bundleGroup.image && bundleGroup.image.style ? imageStyleMapping[bundleGroup.image.style] : ''

  // Apply display style to title based on isTitleVisible
  const titleStyle = {
    position: titlePosition,
    top: titleTop,
    width: titleWidth
  }

  return (
    <div className={`${style.container} ${style.bundleGroup} ${imageClass}`} ref={containerRef}>
      {bundleGroup.image && (
        <div className={`${style.imageSection} ${!isImageVisible ? style.hiddenLandscapeImage : ''}`} ref={imageRef} style={{ position, top }}>
          <img src={bundleGroup.image.url} alt={t("plp.EDITORIAL-IMAGE")} className={style.promoImage} />
        </div>
      )}
      <div className={style.productInfo} ref={infoRef}>
        <div ref={titleRef} style={titleStyle}>
          {(bundleGroup.tag || bundleGroup.associated) && (
            <span className={style.bundleGroupTag}>
              {bundleGroup.tag ? bundleGroup.tag : bundleGroup.associated}
            </span>
          )}
          <h2 className={style.bundleGroupTitle}>{bundleGroup.title}</h2>
          <p className={style.bundleGroupDesc}>{bundleGroup.description}</p>
          {bundleGroup.cta && bundleGroup.cta.visible && (
            <a href={prependLocaleInUrl(bundleGroup.cta.url)} target={bundleGroup.cta.target} className={`${style.cta} ${style.ctaSecondary}`}>
              {t("plp.DISCOVER-MORE")}
            </a>
          )}
        </div>
        <div className={style.bundleGroupBundles}>
          {bundles.map(bundle => {
            const currentLanguage = i18n.language;
            const fullLanguageCode = Object.keys(bundle.translations).find(lang => lang.startsWith(currentLanguage));
            const localizedBundleName = bundle.translations && bundle.translations[fullLanguageCode]
              ? bundle.translations[fullLanguageCode]
              : bundle.translations[currentLanguage]
                ? bundle.translations[currentLanguage]
                : bundle.name;

            return (
              <BundleItem
                key={bundle.id}
                bundle={{ ...bundle, name: localizedBundleName }}
                preview={preview}
                threeItemsLayout={true}
                forceNoImage={true}
                context={context}
                prices={prices}
              />
            );
          })}
        </div>
      </div>
    </div>
  )
}