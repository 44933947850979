import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "../../i18n";
import { ReactComponent as Minimize } from '@svg/minimizeIcon.svg';
import { ReactComponent as Expand } from '@svg/expandIcon.svg';
import { ReactComponent as Close } from '@svg/close.svg';
import { ReactComponent as Error } from '@svg/error.svg';
import { ReactComponent as Check } from '@svg/check.svg';

/* context */
import context from "@context/pdp";
import { setStep, addBundleToCart, setStepBarMinimized } from "@context/pdp/actions";

/* styles */
import style from "@styles/pdp/StepBar.module.scss";

/* utils */
import { getDiscountPrice, getImageUrl, getStepBarProductVariant, getCurrency } from "@utils/helpers";
import { getConfiguredProducts, getToBuyProducts, formatSize } from "@utils/detail";

export default function StepBar() {
  const { state, dispatch } = useContext(context);
  const location = useLocation();
  const isMinimized = state.stepBarMinimized
  const [alert, setAlert] = useState(false);
  const debounce = require('lodash.debounce');
  const [isHovered, setIsHovered] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true)
      if (!state.isBundlePage) {
        setStepBarMinimized({ dispatch }, false)
      }
    }
  
    document.readyState === 'complete' || document.readyState === 'interactive'
      ? handleLoad()
      : window.addEventListener('DOMContentLoaded', handleLoad);
  
    return () => window.removeEventListener('DOMContentLoaded', handleLoad);
  }, []);

  const debouncedHandleMouseEnter = debounce(() => {
    if (state.isBundlePage) {
      setIsHovered(true);
      setTimeout(() => setStepBarMinimized({ dispatch }, false), 200);
    }
  }, 500);

  const handleOnMouseLeave = () => {
    if (state.isBundlePage) {
      setIsHovered(false)
      debouncedHandleMouseEnter.cancel()
      setTimeout(() => setStepBarMinimized({dispatch}, true), 200)
    }
  }

  const { t } = useTranslation("translation");

  const isBundleComplete = state.isBundlePage ? state.step.configured === state.bundle.products.length : getToBuyProducts(state.bundleConfiguration).length > 0

  const collectionPrice = () => {
    let total = 0;
    Object.keys(state.bundleConfiguration).map(sku => {
      if (state.bundleConfiguration[sku]?.toBuy) {
        const price = state.bundleConfiguration[sku]?.finalPrice || state.bundleConfiguration[sku]?.price
        total+=parseFloat(price)
      }
    })

    return total === 0 ? false : total.toFixed(2)
  }
  const CTAOnClick = isBundleComplete
    ? () => addBundleToCart({ state, dispatch })
    : () => {
        setAlert(true);
        const top = document.getElementById('size-picker').getBoundingClientRect().top + window.pageYOffset - 200;
        window.scrollTo({top, behavior: 'smooth'});
      };

      useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const stepFromURL = parseInt(queryParams.get('step'), 10);
      
        if (!isNaN(stepFromURL) && stepFromURL >= 0 && stepFromURL < state.bundle.products.length) {
          if (state.step?.index !== stepFromURL) {
            setStep({ state, dispatch }, stepFromURL);
          }
        }
      }, [location.search, state.bundle.products.length, state.step?.index, dispatch]);

  return (
    <div
      className={`${isMinimized ? style.minimized : ""} ${style.container} ${!state.isBundlePage ? style.collection : ""}`}
      style={{ visibility: isLoaded ? 'visible' : 'hidden' }}
      onMouseEnter={debouncedHandleMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {state.isBundlePage && (<div
        className={style.icons}
        onClick={() => setStepBarMinimized({ dispatch }, !isMinimized)}
      >
        <Minimize className={style.minimize} />
        <Expand className={style.expand} />
      </div>)}
      <div className={`b-container ${style.wrapper} ${state.addToCartComplete && style.wrapperHidden}`}>
        <div className={style.textContainer}>
          {isMinimized ? (
            <>
              <h2 className={style.titleMinimized}>
                {isBundleComplete
                  ? t("step-bar.BUNDLE-COMPLETE")
                  : t("step-bar.COMPLETE-BUNDLE")}
              </h2>
              {!isBundleComplete && (
                <h3 className={style.subtitleMinimized}>
                  {t("step-bar.CHOSE-SIZE-COLOR")}
                </h3>
              )}
            </>
          ) : (
            <>
              {state.isBundlePage && (<h2 className={style.title}>
                {isBundleComplete
                  ? t("step-bar.BUNDLE-COMPLETE")
                  : t("step-bar.COMPLETE-BUNDLE")}
              </h2>)}
              <span className={style.bundleName}>{state.bundle.name}</span>
              <span className={style.status}>
                {state.isBundlePage ? t("step-bar.PRODUCTS-COMPLETED") : t("step-bar.PRODUCTS-ADDED")
                  .replace("%total", state.bundle.products.length)
                  .replace("%init", state.isBundlePage ? state.step.configured : getToBuyProducts(state.bundleConfiguration).length)}
              </span>
            </>
          )}
        </div>
        <div className={style.itemContainer}>
          {state.bundle.products.map((product, index) => {
            const { data } = state.bundle;
            const isConfigured = state.isBundlePage ? getConfiguredProducts(
              state.bundleConfiguration
            ).includes(product.sku) : getToBuyProducts(state.bundleConfiguration).includes(product.sku);
            return (
              <BarItem
                data={{
                  index,
                  product,
                  data,
                  actualIndex: state.step.index,
                  state,
                  dispatch,
                  isConfigured,
                  t,
                }}
                key={index}
              />
            );
          })}
        </div>
        <div className={style.priceContainer}>
          {alert && (
            <div className={style.alert}>
              <div className={style.alertContent}>
                <Error />
                <span>{t('pdp.PLEASE-SELECT-SIZE')}</span>
                <button className={style.alertClose} onClick={() => setAlert(false)}>
                  <Close />
                </button>
              </div>
            </div>
          )}
          <div className={style.priceBlock}>
            {state.bundle.discount > 0 && (
              <span className={style.discount}>
                {t("plp.SAVE").replace("X", state.bundle.discount)}
              </span>
            )}
            <div className={style.priceWrapper}>
              {state.bundle.discount > 0 && (
                <span className={style.priceCrossed}>
                  {getCurrency()} {state.bundle.price.toFixed(2)}
                </span>
              )}
              {state.isBundlePage && (<span className={style.price}>
                {getCurrency()}{" "}
                {getDiscountPrice(
                  state.bundle.price,
                  state.bundle.discount
                ).toFixed(2)}
              </span>)}
              {!state.isBundlePage && collectionPrice() && (<span className={style.price}>
                {getCurrency()}{" "}
                {collectionPrice()}
              </span>)}
            </div>
          </div>
          <div className={style.ctaContainer}>
            <button
              className={`${style.cta} ${
                isBundleComplete ? "" : style.disabled
              }`}
              onClick={CTAOnClick}
            >
              {t(state.isBundlePage ? "pdp.ADD-TO-CART" : getToBuyProducts(state.bundleConfiguration).length > 1 ? "pdp.ADD-ITEMS-TO-CART" : "pdp.ADD-ITEM-TO-CART")}
            </button>
          </div>
        </div>
      </div>
      {state.addToCartComplete && <div className={style.addToCartCompleteWrapper}>
        <span><Check/>{t(state.isBundlePage ? "step-bar.BUNDLE-COMPLETE" : "step-bar.COLLECTION-COMPLETE")}</span>
      </div>}
    </div>
  );
}

function BarItem(props) {
  const { index, product, data, actualIndex, state, dispatch, isConfigured, t } = props.data
  const navigate = useNavigate();
  const location = useLocation();

  const getItemClassName = () => {
    let className = `${index === actualIndex ? style.current : ""} ${style.thumbWrapper}  ${!state.isBundlePage ? style.collection : ""}`
    if (isConfigured) className += ` ${style.thumbWrapperConfigured}`
    return className
  }

  const tickClassName = isConfigured ? `${style.tickIcon} ${style.tickIconActive}` : style.tickIcon

  const size = state.bundleConfiguration[product.sku]?.size
  const label = size ? `${t("pdp.SIZE")}: ${formatSize(size)}` : state.isBundlePage ? t("step-bar.SELECT-SIZE") : ''

  const barImg = {
    prepend: product.prepend,
    sku: product.sku,
    variant: getStepBarProductVariant(product, state.bundleConfiguration, data.fallback_variants, data.fallback_variants_availability),
    type: 'hero'
  }

  const handleClick = () => {
    setStep({ state, dispatch }, index);
    const newURL = new URLSearchParams(location.search);
    newURL.set('step', index);
    navigate(`${location.pathname}?${newURL.toString()}`);
  };

  return (
    <div
      key={index}
      className={getItemClassName()}
      onClick={handleClick}
    >
      <div className={tickClassName}></div>
      <img
        alt={product.sku}
        className={style.thumbImage}
        src={getImageUrl(barImg.sku, barImg.variant, barImg.type, barImg.prepend)}
      />
      <div className={style.thumbText}><span dangerouslySetInnerHTML={{ __html: label }} /></div>
    </div>
  );
}