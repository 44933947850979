import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../i18n";

export function useSizeInseamTranslation(value) {
const { t } = useTranslation("translation");
const [label, setLabel] = useState(value)
  useEffect(() => {
    if(value === 'REG') {
        setLabel(t('size-mapping.regular'))
    } else if (value === 'SHT') {
        setLabel(t('size-mapping.short'))
    } else if (value === 'OS') {
        setLabel(t('size-mapping.one-size'))
    } 
  }, [value]);

  return label
}
