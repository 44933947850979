import { useContext } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import "../../i18n";

/* styles */
import style from "@styles/pdp/Info.module.scss"

/* components */
import Price from "@components/pdp/Price"
import ColorPicker from "@components/pdp/ColorPicker"
import SizePicker from "@components/pdp/SizePicker"
import InseamPicker from "@components/pdp/InseamPicker"
import CollapsibleInfo from "@components/pdp/CollapsibleInfo"
import Gallery from "@components/pdp/Gallery"
import SizeAlert from "@components/pdp/SizeAlert"

/* context */
import context from "@context/pdp"
import { setStep, setToBuy, setSizeAlert, addBundleToCart, toggleSizesPopup } from '@context/pdp/actions';

/* utils */
import { decodeEntities, getDiscountPrice, formatPrice } from "@utils/helpers"
import { onGoToNextProduct } from "@utils/analytics";
import { formatSize, getToBuyProducts } from "@utils/detail"

export default function Info() {
    const { t } = useTranslation("translation");
    const { state, dispatch } = useContext(context);
    const navigate = useNavigate();
    const location = useLocation();

    const GetLabel = (value) => {
        if(value === 'REG') {
            return(t('size-mapping.regular'))
        } else if (value === 'SHT') {
            return(t('size-mapping.short'))
        } else if (value === 'OS') {
            return(t('size-mapping.one-size'))
        } else {
            return value
        }
    }
    const size = state.bundleConfiguration[state.step.product.partNumber]?.size
    const inseam = GetLabel(state.bundleConfiguration[state.step.product.partNumber]?.inseam, state)
    const stockLeft = state.mouseoverSize?.stockLeft ? state.mouseoverSize.stockLeft : state.bundleConfiguration[state.step.product.partNumber]?.stockLeft

    const handleProceed = () => {
      const currentStepIndex = state.step.index
      const totalSteps = state.bundle.products.length

      if (!state.isBundlePage || state.bundleConfiguration[state.step.product.partNumber]?.size) {
        const nextStep = currentStepIndex >= totalSteps - 1 ? 0 : currentStepIndex + 1
        onGoToNextProduct(state.bundle.cross_bundle_id || state.bundle.id, state.bundle.price, state.bundle.discount, nextStep)
        setStep({ state, dispatch }, nextStep)
        
        const newURL = new URLSearchParams(location.search)
        newURL.set('step', nextStep)
        navigate(`${location.pathname}?${newURL.toString()}`)
      } else {
        setSizeAlert({ dispatch }, true)
      }
    }

    const toBuy = () => {
        if (state.bundleConfiguration[state.step.product.partNumber]?.size) {
            setToBuy({ state, dispatch }, !isSetToBuy())
        } else {
            setSizeAlert({ dispatch }, true)
        }
    }

    const isSetToBuy = () => {
        return !state.isBundlePage && state.bundleConfiguration[state.step.product.partNumber]?.toBuy
    }

    const handleAddToCart = () => {
        if (state.bundleConfiguration[state.step.product.partNumber]?.size) {
            addBundleToCart({ state, dispatch })
        } else {
            setSizeAlert({ dispatch }, true)
        }
    }

    const handleCloseAlert = () => {
      setSizeAlert({ dispatch }, false);
    }

    const getCtaClassName = (addToCart) => {
        let className = style.cta
        if (state.step.product.isOOS) {
            className += ` ${style.ctaOOS}`;
        } else {
          if (state.isBundlePage && state.step.nextSteps.length === 0) {
            className += ` ${style.ctaAddToCart}`;
            if (state.step.configured !== state.bundle.products.length) {
              className += ` ${style.ctaDisabled}`;
            }
          } else if (addToCart){
              className += ` ${style.whiteCta}`;
              if (getToBuyProducts(state.bundleConfiguration).includes(state.step.product.partNumber)) {
                  className += ` ${style.removeCta}`;
              }
          }
        }
        return className
    }

    const sizeChartURL = () => {
      if (state.step.product.sizeContext) {
        const locale = document
          .querySelector("#root")
          .getAttribute("data-view-id")
          .toLowerCase();
        const genderToSize = {
          'men': '?category=Men',
          'women': '?category=Women',
          'kids': '?category=Kids',
          'unisex': '?category=Unisex',
        }
        const sizeChart = genderToSize[state.step.product.sizeContext[0].split("-")[0].toLowerCase()] || ''
        return `/${locale}/help-section/product-info/size-charts${sizeChart}`
      } else {
        return null
      }
    }

    return (
      <div className={style.container}>
        <div className={style.wrapper}>
          <h1 className={style.name}>
            {decodeEntities(state.step.product.name)}
          </h1>

          {state.step.product.labels && (
            <div className={style.labels}>
              {state.step.product.labels.map((label, index) => (
                <span key={index} className={style.label}>
                  {label}
                </span>
              ))}
            </div>
          )}

          <Price
            listPrice={formatPrice(
              state.step.product.finalPrice
            )}
            offerPrice={getDiscountPrice(
              state.step.product.finalPrice,
              state.bundle.discount
            )}
          />
        </div>

        <Gallery mobile />

        <div className={style.wrapper}>
          <div className={style.pickerContainer}>
            <div className={style.pickerText}>
              <span className={style.label}>{t("pdp.COLOR")}</span>
              <span className={style.selectedDescription}>: </span>
              <span className={style.selectedDescription}>
                {state.step.product.variant.label.toLowerCase()}
              </span>
            </div>
            <ColorPicker />
          </div>
          <div className={`${style.pickerContainer} ${style.pickerContainerSize}`} id="size-picker">
            <div className={style.pickerText}>
              <span className={style.label}>{t("pdp.SIZE")}: </span>
              {size ? (
                <>
                  <span className={style.selectedDescription}>
                    {formatSize(size)}
                  </span>
                </>
              ) : null}
              {(stockLeft <= 5 && !state.step.product.isOOS)&& <span className={style.selectedStockAlert}>{stockLeft === 1 ? t("pdp.LAST-ITEM") : t("pdp.FEW-ITEMS-LEFT")}</span>}
            </div>
            {state.step.sizeAlert && <SizeAlert onClose={handleCloseAlert} />}
            <SizePicker />
            {state.step.product.sizeContext && (
              <div className={style.sizeGuide}>
                <span className={style.label}>{t("pdp.FIT")}: </span>
                <a href={sizeChartURL()}
                    target="_blank"
                    rel="noreferrer"
                    data-context={state.step.product.sizeContext}
                    className={style.sizeHelperCTA}>
                    <span>{t("pdp.SIZE-CHART")}</span>
                </a>
              </div>
            )}
          </div>
          {state.step.product.hasInseam && (
            <>
              <div className={style.pickerContainer}>
                <div className={style.pickerText}>
                  <span className={style.label}>{t("pdp.INSEAM")}: </span>
                  {state.bundleConfiguration[state.step.product.partNumber]
                    ?.inseam ? (
                    <>
                      <span className={style.selectedDescription}>{inseam}</span>
                    </>
                  ) : null}
                </div>
                <InseamPicker />
              </div>
            </>
          )}

        {!state.isBundlePage && !isSetToBuy() && !state.step.product.isOOS && !state.isBundlePage && (
            <button className={getCtaClassName(true)} onClick={toBuy}>
                {t("pdp.ADD-PRODUCT")}
            </button>
        )}

        {!state.isBundlePage && isSetToBuy() && (
            <button className={getCtaClassName(true)} onClick={toBuy}>
                {t("pdp.REMOVE-PRODUCT")}
            </button>
        )}

          {state.step.product.isOOS ? (
            <button className={getCtaClassName()}>
              {t("pdp.OUT-OF-STOCK")}
            </button>
          ) : state.step.nextSteps.length === 0 && state.isBundlePage ? (
            <button className={getCtaClassName()} onClick={handleAddToCart}>
              {t("pdp.ADD-TO-CART")}
            </button>
          ) : state.isBundlePage ? (
            <button className={getCtaClassName()} onClick={handleProceed}>
              {t("pdp.NEXT-PRODUCT")}
            </button>
          ) : ''}
          <CollapsibleInfo />
        </div>
      </div>
    );
}