import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

function init(){
    const rootElement = document.getElementById('root')
// Set pageType as 'bundle-group' or 'collection-group'
    const pageType = rootElement.getAttribute('data-page-type') || 'bundle-group'

    const root = ReactDOM.createRoot(rootElement)
    root.render(
        <React.StrictMode>
            <App pageType={pageType} />
        </React.StrictMode>
    );
}
init()


function getPath() {
    const a = document.createElement("a")
    a.href = window.location

    return a.pathname
}

// Fix when page changes from bundles to collection
let prevPath = getPath()
window.addEventListener('state-changed', function() {
    if (prevPath === null) {
        prevPath = getPath()
    } else if (getPath() !== prevPath) {
        setTimeout(function() {
            prevPath = getPath()
            if (document.getElementById('root')) {
                init()
            }
        }, 500)
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
