import { createContext } from "react"

const context = createContext()
const pageType = document.getElementById('root').getAttribute('data-page-type') || 'bundle-group'
const isBundlePage =  pageType === 'bundle-group'

export const initialContext = {
    bundles: [],
    bundleGroup: [],
    collectionGroup: [],
    prices: [],
    filters: {
        all: {},
        active: {},
        result: []
    },
    loading: true,
    sizesPopup: false,
    isBundlePage
}

export default context