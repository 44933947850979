import { getStoreKey } from "@utils/detail";

export default function store(state) {
    const storeKey = getStoreKey(state.bundle.id);
    const {
        bundle,
        loading,
        cartLoading,
        sizesPopup,
        sliderGallery,
        preview,
        ...rest
    } = state

    sessionStorage.setItem(storeKey, JSON.stringify({
        ...rest,
        step: {
            index: state.step?.index || 0,
            configured: state.step?.configured || 0,
        }
    }));
}