import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import '../../i18n'

/* styles */
import style from '@styles/plp/Header.module.scss'

export default function Header({ titleKey, subtitleKey }) {
  const { t } = useTranslation('translation')
  const [hideIntro] = useState(false)

  return (
    !hideIntro && (
      <div className={style.container}>
        <h1 className={style.title}>{t(titleKey)}</h1>
        <p className={style.text}>{t(subtitleKey)}</p>
      </div>
    )
  )
}