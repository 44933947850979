import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import translationEN from "./public/locales/en/translation.json";
import translationCS from "./public/locales/cs/translation.json";
import translationIT from "./public/locales/it/translation.json";
import translationDA from "./public/locales/da/translation.json";
import translationDE from "./public/locales/de/translation.json";
import translationES from "./public/locales/es/translation.json";
import translationFR from "./public/locales/fr/translation.json";
import translationNL from "./public/locales/nl/translation.json";
import translationPL from "./public/locales/pl/translation.json";
import translationPT from "./public/locales/pt/translation.json";
import translationSV from "./public/locales/sv/translation.json";

const resources = {
  cs: {
    translation: translationCS
  },
  en: {
    translation: translationEN
  },
  it: {
    translation: translationIT
  },
  da: {
    translation: translationDA
  },
  de: {
    translation: translationDE
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  nl: {
    translation: translationNL
  },
  pl: {
    translation: translationPL
  },
  pt: {
    translation: translationPT
  },
  sv: {
    translation: translationSV
  },
};

i18n
  .use(Backend)

  .use(initReactI18next)

  .init({
    resources,
    fallbackLng: "en",
    debug: false,
    lng: document.querySelector('#root')?.getAttribute('data-view-id') ? document.querySelector('#root')?.getAttribute('data-view-id').split('_')[0] : "en",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;