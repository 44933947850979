import logger from "@utils/logger";
import store from "@context/pdp/store";
import { setConfiguredAndNextSteps, checkAvailableInseam, getDefaultInseam, getDefaultOneSizeIfAvailable} from "@utils/detail";
import { getDiscountPrice, isTestingEnv } from "@utils/helpers";

const removeDuplicatedSizes = (sizes) => {
  if (sizes) {
    sizes.unshift(
      sizes.splice(
        sizes.findIndex((elt) => elt.inseam === "REG"),
        1
      )[0]
    );
    sizes = sizes.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.size === value.size && t.inseamLabel === value.inseamLabel
        )
    );
  }
  return sizes
}

const reducer = (state, action) => {
    let nextState = {}

    switch (action.type) {
      case "GET_BUNDLE":
        nextState = {
          ...state,
          bundle: action.bundle,
        };
        break;

      case "SET_STEP":
        nextState = {
          ...state,
          step: {
            ...state.step,
            ...action.step,
          },
          loading: false,
          stepBarMinimized: state.isBundlePage,
        };

        // one size
        if (removeDuplicatedSizes(action.step.product.sizes).length === 1) {
          const validOneSize = getDefaultOneSizeIfAvailable(action.step.product.sizes)

          let productConfig = nextState.bundleConfiguration[action.step.product.partNumber];

          if(validOneSize){
            productConfig = {
              ...productConfig,
              catId: validOneSize.catId,
              size: validOneSize.size,
              stockLeft: validOneSize.stockLeft
            }
          } else if(productConfig){
            delete productConfig.catId;
            delete productConfig.size;
          }

          nextState.bundleConfiguration = {
            ...nextState.bundleConfiguration,
            [action.step.product.partNumber]: {
              ...productConfig,
            },
          };
        }

        // default inseam
        if (action.step.product.hasInseam) {
          const productConfig =
            nextState.bundleConfiguration[action.step.product.partNumber];
          nextState.bundleConfiguration = {
            ...nextState.bundleConfiguration,
            [action.step.product.partNumber]: {
              ...productConfig,
              inseam:
                productConfig?.inseam ||
                getDefaultInseam(nextState.step.product.inseam),
            },
          };
        }

        // analytics data
        nextState.bundleConfiguration = {
          ...nextState.bundleConfiguration,
          [action.step.product.partNumber]: {
            ...nextState.bundleConfiguration[action.step.product.partNumber],
            name: action.step.product.name,
            price: getDiscountPrice(action.step.product.finalPrice, state.bundle.discount),
            finalPrice: action.step.product.finalPrice,
            colorDescription: action.step.product.variant.label,
            colorCode: action.step.product.variant.code,
            partNumbers: action.step.product.partNumbers,
            fullProduct: action.step.product,
          }
        }
        // add total configured products and next steps
        nextState = setConfiguredAndNextSteps(nextState);

        break;

      case "SET_PDP_LOADING":
        nextState = {
          ...state,
          loading: true,
        };
        window.scrollTo(0, 0);
        break;

      case "SET_PDP_CART_LOADING":
        nextState = {
          ...state,
          cartLoading: true,
        };
        window.scrollTo(0, 0);
        break;

      case "STOP_PDP_CART_LOADING":
        nextState = {
          ...state,
          cartLoading: false,
        };
        break;

      case "SET_CHOICE_VARIANT":
        const inseam = action?.inseamData?.inseamKey || getDefaultInseam(action.stepProduct.inseam)
        nextState = {
          ...state,
          stepBarMinimized: false,
          bundleConfiguration: {
            ...state.bundleConfiguration,
            [action.product]: {
              variant: action.variant,
              catId: action?.sizeData?.stock ? action.sizeData.catId : null,
              size: action?.sizeData?.stock ? action.sizeData.size : null,
              stockLeft: action?.sizeData?.stockLeft,
              name: action.stepProduct.name,
              price: getDiscountPrice(
                action.stepProduct.finalPrice,
                state.bundle.discount
              ),
              colorDescription: action.stepProduct.variant.label,
              colorCode: action.stepProduct.variant.code,
              partNumbers: action.stepProduct.partNumbers,
              inseam
            },
          },
          step: {
            ...state.step,
            product: action.stepProduct,
          },
          loading: false,
        };

        //check if inseam is defined and if inseam has avilabel size, othwerise deselecte size
        if (action?.hasInseam) {
          if(!checkAvailableInseam(action)){
            delete nextState.bundleConfiguration[action.product].size
          }
        }

        // one size available autoselect
        if ((removeDuplicatedSizes(action?.stepProduct.sizes).length === 1) && checkAvailableInseam(action)) {
          const validOneSize = getDefaultOneSizeIfAvailable(action?.stepProduct.sizes, action?.sizeData?.inseamKey)
          if(validOneSize){
            nextState.bundleConfiguration[action.product].size = validOneSize.size
            nextState.bundleConfiguration[action.product].catId = validOneSize.catId
          } else {
            delete nextState.bundleConfiguration[action.product].size
            delete nextState.bundleConfiguration[action.product].catId
          }
        }

        // add total configured products and next steps
        nextState = setConfiguredAndNextSteps(nextState);

        break;

      case "SET_CHOICE_SIZE":
        nextState = {
          ...state,
          bundleConfiguration: {
            ...state.bundleConfiguration,
            [action.product]: {
              ...state.bundleConfiguration[action.product],
              size: action.size,
              stockLeft: action.stockLeft,
              catId: action.catId,
              sizeLabel: action.sizeLabel,
            },
          },
          stepBarMinimized: false
        };

        // add total configured products and next steps
        nextState = setConfiguredAndNextSteps(nextState);

        // remove sizeAlert on size selection
        delete nextState.step?.sizeAlert;
        break;

      case "SET_TO_BUY":
        nextState = {
          ...state,
          bundleConfiguration: {
            ...state.bundleConfiguration,
            [action.product]: {
              ...state.bundleConfiguration[action.product],
              toBuy: action.toBuy,
            },
          },
          stepBarMinimized: false
        };

        // add total configured products and next steps
        nextState = setConfiguredAndNextSteps(nextState);

        break;

      case "SET_CHOICE_INSEAM":
        const newCatId = state.step.product.sizes
        .find(size => size.inseamKey === action.inseam.inseamKey && size.size === state.bundleConfiguration[action.product]?.size && size.stock)

        nextState = {
          ...state,
          bundleConfiguration: {
            ...state.bundleConfiguration,
            [action.product]: {
              ...state.bundleConfiguration[action.product],
              catId: action.inseam.catId,
              inseam: action.inseam.inseamKey,
            },
          },
        };
        
        if(newCatId){
          nextState.bundleConfiguration[action.product].catId = newCatId.catId
        } else {
          delete nextState.bundleConfiguration[action.product].catId
          delete nextState.bundleConfiguration[action.product].size
        }

        // remove size and catId on inseam change
        // nextState = removeSizeOnInseamChange(nextState, action);

        // add total configured products and next steps
        nextState = setConfiguredAndNextSteps(nextState);

        break;

      case "SET_STEP_SIZE_ALERT":
        nextState = {
          ...state,
          step: {
            ...state.step,
            sizeAlert: action.active,
          },
        };
        break;

      case "ADD_TO_CART":
        nextState = {
          ...state,
          bundleConfiguration: {
            [state.step.product.partNumber]: {
              inseam:
                getDefaultInseam(state.step.product.inseam),
            },
          },
          step: {
            ...state.step,
            configured: 0,
          },
          addToCartComplete: true
        }
        break;

      case "RESET_ADD_TO_CART_COMPLETE":
        nextState = {
          ...state,
          addToCartComplete: false
        }
        break;

      case "TOGGLE_SIZES_POPUP":
        nextState = {
          ...state,
          sizesPopup: !state.sizesPopup,
        };
        break;

      case "SET_STEP_BAR_MINIMIZED":
        nextState = {
          ...state,
          stepBarMinimized: action.minimized
        };
        break;
      
      case "TOGGLE_SLIDER_GALLERY":
        nextState = {
          ...state,
          sliderGallery: !state.sliderGallery,
        };
        break;

      case "SET_MOUSEOVER_SIZE":
        nextState = {
          ...state,
          mouseoverSize: action.size
        }
        break;

      case "REMOVE_MOUSEOVER_SIZE":
        nextState = {
          ...state,
          mouseoverSize : {}
        }
        break;

      default:
        nextState = state;
    }


    if (isTestingEnv()) {
      logger(state, nextState, action);
    }
    store(nextState)

    return nextState;
}

export default reducer;