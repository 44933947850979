export const shoesSizeTable = {
    men: [
        { us: 6, uk: 5, eu: 38, cm: 24 },
        { us: 6.5, uk: 5.5, eu: 38.5, cm: 24.5 },
        { us: 7, uk: 6, eu: 39, cm: 25 },
        { us: 7.5, uk: 6.5, eu: 40, cm: 25.5 },
        { us: 8, uk: 7, eu: 40.5, cm: 26 },
        { us: 8.5, uk: 7.5, eu: 41, cm: 26.5 },
        { us: 9, uk: 8, eu: 42, cm: 27 },
        { us: 9.5, uk: 8.5, eu: 42.5, cm: 27.5 },
        { us: 10, uk: 9, eu: 43, cm: 28 },
        { us: 10.5, uk: 9.5, eu: 44, cm: 28.5 },
        { us: 11, uk: 10, eu: 44.5, cm: 29 },
        { us: 11.5, uk: 10.5, eu: 45, cm: 29.5 },
        { us: 12, uk: 11, eu: 45.5, cm: 30 },
        { us: 12.5, uk: 11.5, eu: 46, cm: 30.5 },
        { us: 13, uk: 12, eu: 47, cm: 31 },
        { us: 13.5, uk: 12.5, eu: 47.5, cm: 31.5 },
        { us: 14, uk: 13, eu: 48, cm: 32 }
    ],
    women: [
        { us: 5, uk: 3, eu: 36, cm: 22 },
        { us: 5.5, uk: 3.5, eu: 36.5, cm: 22.5 },
        { us: 6, uk: 4, eu: 37, cm: 23 },
        { us: 6.5, uk: 4.5, eu: 37.5, cm: 23.5 },
        { us: 7, uk: 5, eu: 38, cm: 24 },
        { us: 7.5, uk: 5.5, eu: 38.5, cm: 24.5 },
        { us: 8, uk: 6, eu: 39, cm: 25 },
        { us: 8.5, uk: 6.5, eu: 39.5, cm: 25.5 },
        { us: 9, uk: 7, eu: 40, cm: 26 },
        { us: 9.5, uk: 7.5, eu: 40.5, cm: 26.5 },
        { us: 10, uk: 8, eu: 41, cm: 27 },
        { us: 10.5, uk: 8.5, eu: 41.5, cm: 28 },
        { us: 11, uk: 9, eu: 42, cm: 28.5 },
        { us: 11.5, uk: 9.5, eu: 42.5, cm: 29 }
    ]
}

export const sortOptions = (t) => [
    { id: 0, label: t("plp.FILTERS.RELEVANCE") },
    { id: 1, label: t("plp.FILTERS.PRICE-LOW-HIGH") },
    { id: 2, label: t("plp.FILTERS.PRICE-HIGH-LOW") },
]

export const getTranslations = (t) => ({
    gender: {
      label: t("plp.FILTERS.GENDER"),
      options: {
        men: t("plp.FILTERS.MEN"),
        women: t("plp.FILTERS.WOMEN"),
        kids: t("plp.FILTERS.KIDS"),
        unisex: t("plp.FILTERS.UNISEX")
      }
    },
    activity: {
      label: t("plp.FILTERS.ACTIVITY"),
      options: {
        hiking: t("plp.FILTERS.HIKING"),
        mountaineering: t("plp.FILTERS.MOUNTAINEERING"),
        climbing: t("plp.FILTERS.CLIMBING"),
        training: t("plp.FILTERS.TRAINING"),
        lifestyle: t("plp.FILTERS.LIFESTYLE"),
        "mountain biking": t("plp.FILTERS.MOUNTAIN-BIKING"),
        "skiing and snowboarding": t("plp.FILTERS.SKIING"),
        "ski touring": t("plp.FILTERS.SKI-TOURING"),
        "via ferrata": t("plp.FILTERS.VIA-FERRATA"),
        "trail running": t("plp.FILTERS.TRAIL-RUNNING")
      }
    },
    accessories: {
      label: t("plp.FILTERS.ACCESSORIES"),
      options: {
        accessories: t("plp.FILTERS.ACCESSORIES"),
        "not accessories": t("plp.FILTERS.NOT-ACCESSORIES")
      }
    },
    collection: {
      label: t("plp.FILTERS.COLLECTIONS"),
      options: {
        collection: t("plp.FILTERS.COLLECTIONS")
      }
    }
  })