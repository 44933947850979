import { useTranslation } from "react-i18next"

import style from '@styles/generic/Dropdown.module.scss'

export default function Dropdown({ label, options, onSelect }) {
  const { t } = useTranslation("translation")
  
  const handleSelect = (event) => {
    const selectedId = parseInt(event.target.value, 10)
    onSelect(selectedId)
  }
  
  return (
    <div className={style.dropdown}>
      <label className={style.dropdownButton}>
          <div>
              {t('plp.FILTERS.SORTING')}: {label}
              <span className={`${style.caret}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="15" height="15">
                      <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z"/>
                  </svg>
              </span>
          </div>
          <select className={style.select} onChange={handleSelect}>
              {options.map((option) => (
                  <option key={option.id} value={option.id}>
                  {option.label}
                  </option>
              ))}
          </select>
      </label>
    </div>
  )
}