import { useContext, useState, useEffect } from "react";

/* styles */
import style from "@styles/pdp/MobileProductBar.module.scss";

/* context */
import context from "@context/pdp";
import { setStep } from "@context/pdp/actions";

/* context */
import { formatSize } from "@utils/detail";

import { useTranslation } from "react-i18next";
import "../../i18n";


export default function MobileProductBar() {
    const { state, dispatch } = useContext(context);
    const [scrolled, setScrolled] = useState(false)
    const { t } = useTranslation("translation");

    const getItemClassName = (index, size, toBuy, isBundlePage) => {
        let className = style.item
        if (index === state.step?.index) className += ` ${style.active}`
        if (isBundlePage && size) className += ` ${style.configured}`
        if (!isBundlePage && toBuy) className += ` ${style.configured}`
        return className
    }

    useEffect(() => {
        window.onscroll = () => {
            if (!scrolled && window.scrollY >= 160) setScrolled(true)
            if (scrolled && window.scrollY < 160) setScrolled(false)
        }
    }, [scrolled])


    const className = `${style.container} ${scrolled ? style.containerScrolled : ''}`

    return state.bundle ? <div className={className}>
        {state.bundle.products.map((product, index) => {
            const size = state?.bundleConfiguration[product.sku]?.size
            const toBuy = state?.bundleConfiguration[product.sku]?.toBuy
            return <div key={index} className={getItemClassName(index, size, toBuy, state.isBundlePage)} onClick={() => setStep({ state, dispatch }, index)}>
                <div className={style.tick}></div>
                <span className={style.label}>{t('pdp.PRODUCT')} {index + 1}</span>
                {size ? <span className={style.size}>Size: {formatSize(size)}</span>
                : <span className={style.selectSize}>{t('step-bar.SELECT-SIZE').toLowerCase()}</span>}
            </div>
        })}
    </div> : null
}