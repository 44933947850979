export const sortSizes = (sizesList) => {
  const order = ["XXS", "XS","XS/S", "S", "S/M", "M","M/L", "L", "L/XL", "XL", "XXL", "3XL", "1X", "2X", "3X"];
  // , "2","3","4","5","6","7"
  const sortWithOrder = (a, b) => {
    return order.indexOf(a.display) - order.indexOf(b.display);
  };
  const sortWithNumber = (a, b) => +a.size - +b.size;

  const sortWithAge = (a, b) => +a.size?.split(' ')[0] - +b.size?.split(' ')[0]; //todo not [0] but parse int split(' ')[0]

  return sizesList.sort(
    isNaN(sizesList[0]?.size) ? isNaN(sizesList[0]?.size?.split(' ')[0]) ? sortWithOrder : sortWithAge : sortWithNumber
  );
};

export const getUniqueID = (sizesObj) => {
  if (typeof sizesObj !== "undefined") {
    return sizesObj.uniqueID;
  }
};

export const serializeProductData = (
    data,
    variantCode
) => {
  const buyable = data.variants.find(v => v.id.indexOf(`:${variantCode}:`) > 0)?.productInventoryState === 'InStock' && data.variants.find(v => v.id.indexOf(`:${variantCode}:`) > 0)?.buyable
  const prices = data.variants.map(variant => variant.price.current)

  const colors = data.attributes.find(attr => attr.type === 'color').options.map((val) => ({
    code: val.value,
    label: val.label,
    swatchColors: val.swatch.color
  }))

  const type = data.attributes.find(attr => attr.type === 'size') === undefined ? 'waist' : 'size'
  const sizeAttribute = data.attributes.find(attr => attr.type === type).options.map((val) => ({
    code: val.value,
    label: val.label.toUpperCase(),
  }))
  
  let inseamAttributes = []
  if (data.attributes.find(attr => attr.type === 'length') || data.attributes.find(attr => attr.type === 'inseam')) {
    const inseamType = data.attributes.find(attr => attr.type === 'length') === undefined ? 'inseam' : 'length'
    inseamAttributes = data.attributes.find(attr => attr.type === inseamType).options.map((val) => ({
      code: val.value,
      available: val.available,
      label: val.label,
    }))
  }

  const variantData = data.variants.filter(v => v.id.indexOf(`:${variantCode}:`) > 0)
  const sizes = variantData.map(v => {
    const inseam = v.id.split(':')[4]
    return {
      size: v.id.split(':')[3],
      inseamKey: inseam,
      stock: v.productInventoryState === 'InStock' && v.buyable,
      stockLeft: v.notifyMe ? 0 : undefined,
      catId: v.id,
      sizeKey: sizeAttribute.find(s => s.code === v.attributes[type]).label,
      display: sizeAttribute.find(s => s.code === v.attributes[type]).label
    }
  })

  const inseams = inseamAttributes.map(v => {
    return {
      stock: v.available,
      inseamKey: v.code,
      inseam: v.label
    }
  })

  const benefitsSection = data.details?.find(section => section.id === 'Benefits')
  const benefits = benefitsSection?.data.map(benefit => ({
    label: benefit.label,
    text: benefit.text,
    thumbnail: benefit.thumbnail
  })) || []

  const productTextSection = data.details?.find(section => section.id === 'productText')
  const longDescription = productTextSection?.data[0]?.label || ''

  const featureDescriptionSection = data.details?.find(section => section.id === 'featureDescription')
  const technology = featureDescriptionSection?.data.map(tech => ({
    label: tech.label,
    text: tech.text
  })) || []

  return {
    avgRating: data?.rating,
    originalVariants: data?.variants,
    buyable: buyable,
    inseam: inseams.length ? inseams : null,
    colors: colors,
    sizes: sizes,
    variant: colors.find(color => color.code === variantData[0].attributes.color),
    finalPrice: (Math.max(...prices).toFixed(2)).toString(),
    name: data.name,
    id: data.id.substring(0, 8) + variantCode,
    masterId: data.id,
    longDescription: longDescription,
    technology: technology,
    benefits: benefits,
    xcatentry_ProductRating: data.rating,
    xcatentry_ProductRatingCount: data.numReviews,
    sizeContext: data.sizeChart !== "DISABLED" ? data.sizeChart : false,
    partNumber: data.id.substring(0, 8).substring(4),
    isOOS: !data.variants.some(
      (item) => item.productInventoryState === 'InStock' && item.buyable === true
    ),
    hasInseam: inseams.length ? true : false
  }
};

export const getStoreKey = (bundleId) =>
  `${
    document.getElementById("root").dataset.viewId
  }-bundleSettings-${bundleId}`;

export const getConfiguredProducts = (bundleConfiguration) =>
  Object.keys(bundleConfiguration).filter(
    (sku) => bundleConfiguration[sku].size
  );

export const getToBuyProducts = (bundleConfiguration) =>
    Object.keys(bundleConfiguration).filter(
        (sku) => bundleConfiguration[sku]?.toBuy
    );

export const formatSize = (size) => size;

export const getSizeChart = async (sizeContext) => {
  const domain =
    window.location.hostname === "localhost" ||
    window.location.hostname === "dev-eu-tnf-product-finder.netlify.app"
      ? "www.thenorthface.co.uk"
      : window.location.hostname;
  const locale = document
    .querySelector("#root")
    .getAttribute("data-view-id")
    .toLowerCase();
  const genderToSize = {
    'men': '?category=Men',
    'women': '?category=Women',
    'kids': '?category=Kids',
    'unisex': '?category=Unisex',
  }
  const sizeChart = genderToSize[sizeContext] || ''
  const response = await fetch(
    `https://${domain}/${locale}/help-section/product-info/size-charts${sizeChart}`
  );
  return response.text();
};

export const setConfiguredAndNextSteps = (nextState) => {
  nextState.step = {
    ...nextState.step,
    configured: getConfiguredProducts(nextState.bundleConfiguration).length,
  };

  nextState.step.nextSteps = nextState.bundle.products.reduce(
    (steps, product, index) => {
      if (
        !getConfiguredProducts(nextState.bundleConfiguration).includes(
          product.sku
        ) &&
        nextState.step.index !== index
      ) {
        steps.push(index);
      }
      return steps;
    },
    []
  );

  return nextState;
};

export const removeSizeOnInseamChange = (nextState, action) => {
  const newInseamSizeStock = nextState.step.product.sizes.find(
    ({ size, inseam }) =>
      size === nextState.bundleConfiguration[action.product].size &&
      inseam === action.inseam
  )?.stock;

  if (!newInseamSizeStock) {
    delete nextState.bundleConfiguration[action.product].size;
    delete nextState.bundleConfiguration[action.product].catId;
  }

  return nextState;
};

export const getDefaultInseam = (inseams) => {
  if (inseams) {
    // return REG if exists
    if (inseams?.some((inseam) => inseam.inseamKey === "REG" && inseam.stock))
      return "REG";
    // return first inseam
    return inseams[0].stock ? inseams[0].inseam : null;
  } else {
    return null;
  }
};

export const getGalleryModal = async (product, variant) => {
    const urlLangId = document.querySelector('meta[name="langId"]')?.content || '-11'
    const storeId = document.querySelector('meta[name="storeId"]')?.content || '7005'
    const domain = (window.location.hostname === 'localhost' || window.location.hostname === 'dev-eu-tnf-product-finder.netlify.app') ? 'www.thenorthface.co.uk' : window.location.hostname
    const response = await fetch(`https://${domain}/shop/VFQuickViewDisplay?variationId=${variant}&urlLangId=${urlLangId}&langId=${urlLangId}&productId=${product}&storeId=${storeId}&requesttype=ajax`)
    return response.text()
}

export const checkAvailableInseam = (action) => {
  if (
    !(
      action?.inseamData?.inseamKey ||
      getDefaultInseam(action.stepProduct.inseam)
    )
  ) {
    return false;
  }
  return true;
};


export const getDefaultOneSizeIfAvailable = (sizes, inseamKey) => {
  let size = null
  if(inseamKey){
  size = sizes.find(
    (item) => item.stock && item.inseamKey === inseamKey
  );
  } else{
    if(sizes[0].stock) size = sizes[0]
  }
  return size
}