import { useContext, useState, useEffect } from "react";

/* styles */
import style from "@styles/pdp/Gallery.module.scss";

/* context */
import context from "@context/pdp";
import { toggleSliderGallery } from '@context/pdp/actions';

/* components */
import CircleLoader from "@components/plp/CircleLoader";

/* utils */
import { getDetailImageUrl, getDetailZoomImageUrl, mapRange } from "@utils/helpers";
import { getProduct } from "@utils/fetch";

export default function Gallery({ mobile }) {
  const { state, dispatch } = useContext(context);
  const [images, setImages] = useState([]);
  const products = state.bundle.products;

  // get current product index from state
  const productStep = state.step.index;
  const sku = products[productStep].sku
  const variant = state.step.product.variant.code

  useEffect(() => {
    const productId = products[productStep].prepend + sku + variant

    getProduct(productId).then(data => {
      const galleryImages = data?.gallery ? data.gallery.map(i => i.src) : []
      setImages(galleryImages)
    });
  }, [productStep, products, variant, sku]);

  const className = mobile
    ? `${style.container} ${style.mobile}`
    : `${style.container} ${style.desktop}`;

  return (
    <div className={className}>
      {images.length === 0 ? (
        <div className={style.loaderContainer}>
          <CircleLoader />
        </div>
      ) : (
        images.map((value, index) => 
          value.substr(value.length - 3) !== 'mp4' ? 
            <Image key={index} url={value} alt={products[productStep].sku + '_' + products[productStep].variant} dispatch={dispatch}/> : 
            <Video key={index} url={value} alt={products[productStep].sku + '_' + products[productStep].variant} dispatch={dispatch}/>
        )
      )}
    </div>
  );
}

function Video({ url, alt, dispatch }) {
  return (
    <div className={style.thumb}>
      <video src={url} alt={alt} controls muted playsInline={true} autoPlay loop controlsList='nodownload'></video>
    </div>
  );
}
function Image({ url, alt, dispatch }) {
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const [mouseOver, setMouseOver] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const isMobile = window.innerWidth < 768

  const handleMouseMove = event => {
    var bounds = event.target.getBoundingClientRect();
    var x = event.clientX - bounds.left;
    var y = event.clientY - bounds.top;
    setCoords({
      x: mapRange(x, 0, bounds.width, 0, 100),
      y: mapRange(y, 0, bounds.height, 0, 100),
    })
  };

  const handleMouseEnter = () => {
    if(!isMobile) {
      setMouseOver(true);
      setTimeout(()=>setOpacity(1), 150);
    }
  }

  const handleMouseLeave = () => {
    if(!isMobile) {
      setOpacity(0);
      setTimeout(()=>setMouseOver(false), 600);
    }
  }

  return (
    <div className={style.thumb} onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <img src={getDetailImageUrl(url)} alt={alt} />
      {(!isMobile && mouseOver) && <>
        <div className={style.zoom} x={coords.x * 2} y={coords.y * 2} style={{
          opacity: opacity,
          backgroundImage: `url(${getDetailZoomImageUrl(url)})`,
          backgroundPosition: `${coords.x}% ${coords.y}%`
        }}></div>
        {/* Click popup removed */}
      </>}
    </div>
  );
}

