import logger from "@utils/logger"
import { filterBundleList } from "@utils/listing"
import { isTestingEnv } from "@utils/helpers"

const reducer = (state, action) => {
  let nextState = {}

  switch (action.type) {
    case "SET_BUNDLES":
      nextState = {
        ...state,
        bundles: action.bundles,
        loading: false,
      }
      break

    case "SET_PRODUCT_PRICES":
      nextState = {
        ...state,
        prices: action.prices,
      }
      break
    
    case "SET_BUNDLE_GROUP":
      nextState = {
        ...state,
        bundleGroup: action.bundleGroup,
        loading: false,
      }
      break
    
    case "SET_COLLECTION_GROUP":
      nextState = {
        ...state,
        collectionGroup: action.collectionGroup,
        loading: false,
      }
      break

    case "SET_COMBINED_RESULTS":
      nextState = {
        ...state,
        filters: {
            ...state.filters,
            result: action.payload,
        },
        loading: false,
      }
      break

    case "SET_QUERY_FILTERS":
      const active = action.queryFilters
      const combinedResults = [...state.bundles, ...state.bundleGroup, ...state.collectionGroup]
      const result = combinedResults.filter((bundle) =>
          filterBundleList(bundle, active)
      )
      nextState = {
          ...state,
          filters: {
              ...state.filters,
              active,
              result,
          },
          filterLoading: false,
      }
      break

    case "TOGGLE_FILTER":
      const updatedActiveFilters = { ...action.activeFilters }
      const resultAfterToggle = action.result

      nextState = {
        ...state,
        filterLoading: true,
        filters: { ...state.filters, active: updatedActiveFilters, result: resultAfterToggle },
      }
      break

      case "SET_SORTING":
        nextState = {
          ...state,
          filters: {
            ...state.filters,
            result: action.sortedBundles,
            sortOption: action.sortOption,
          },
        }
        break

    case "SET_LOADING":
      nextState = {
        ...state,
        loading: action.loading,
      }
      break

    case "SET_FILTER_LOADING":
      nextState = {
        ...state,
        filterLoading: action.loading,
      }
      break

    case "SET_BUNDLES_FILTERS":
      nextState = {
        ...state,
        filters: {
          ...state.filters,
          all: action.filters,
        },
      }
      break
    
    case "SET_COLLECTIONS_FILTERS":
      nextState = {
        ...state,
        filters: {
          ...state.filters,
          all: action.filters,
        },
      }
      break

    case "CLEAR_ALL_FILTERS":
      nextState = {
        ...state,
        filters: {
          ...state.filters,
          active: {},
          result: state.bundles,
        },
      }
      break

    default:
      nextState = state
  }

  if (isTestingEnv()) {
    //logger(state, nextState, action)
  }

  return nextState
}

export default reducer